import { GoogleLogin, GoogleOAuthProvider } from "@react-oauth/google";
import axios from "axios";
import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useAuthToken } from "../context/token";
import classes from "./Signup.module.css";
import Card from "../components/Card";
import showErrorSnackbar from "../components/Snackbar";
// import backgroundImage from "../assets/signup.jpg";

const Signup = () => {
	const headers = {
		"Content-Type": "application/json", // Set the content type if needed
	};
	const loginEmailer = async (message) => {
		const token = message.split(",")[1];
		const emailMsg = message.split(",")[0];
		// console.log(token, emailMsg)

		try {
			axios.post(
				"/api/bt/sendEmail",
				{
					to: "divyansh@26milescapital.com",
					subject: "Someone logged in",
					message: emailMsg,
				},
				{
					headers: {
						Authorization: `Bearer ${token}`, // Use 'Bearer' followed by a space before the token
						"Content-Type": "application/json",
					},
				}
			);
		} catch (err) {
			console.log("emailer error", err.message);
		}
	};
	const navigate = useNavigate();
	const { setAuthToken } = useAuthToken();

	//const url = "http://localhost:5000"

	const responseMessage = async (response) => {
		const res = await axios.post(`/api/getToken`, {
			userCred: response.credential,
		});
		// setExpiryTime(res.data.expTime)
		setAuthToken(res.data.token);
		localStorage.setItem("token", res.data.token);
		loginEmailer(
			`User logged in to Client Dashboard: ${res.data.email},${res.data.token}`
		);
		// setAuthEmail(res.data.email)
		navigate("/dashboard");
	};

	const errorMessage = (error) => {
		console.log("error", error);
	};
	// const [email, setEmail] = useState("");
	// const [password, setPassword] = useState("");
	// const [username, setUsername] = useState("");
	// const [loading1, setLoading1] = useState(false);
	// const [loading2, setLoading2] = useState(false);
	// const [loading3, setLoading3] = useState(false);
	const [isClient, setClient] = useState(true);

	const emailRegex = new RegExp(
		/^[A-Za-z0-9_!#$%&'*+\/=?`{|}~^.-]+@[A-Za-z0-9.-]+$/gm
	);
	//   const handleSubmit = async (e) => {
	//     e.preventDefault();

	//     const res = await axios.post(
	//       "http://localhost:5000/signup",
	//       {
	//         headers: {
	//           "Content-Type": "application/json",
	//         },
	//       },
	//       {
	//         email: email,
	//         password: password,
	//       }
	//     );
	//     console.log(res);

	//   };
	const emailInputRef = useRef();
	const passwordInputRef = useRef();
	const submitHandler = async (event) => {
		event.preventDefault();
		const enteredEmail = emailInputRef.current.value;
		const enteredPassword = passwordInputRef.current.value;


		try{
		const res = await axios.post('/api/auth/login', {
			email:enteredEmail,
			password:enteredPassword,
		})

		// console.log(res)

		setAuthToken(res.data.token)
		localStorage.setItem("token", res.data.token);
		loginEmailer(
			`User logged in to Client Dashboard: ${enteredEmail},${res.data.token}`
		);
		// setAuthEmail(res.data.email)
		navigate("/dashboard");
		}catch(err){
			showErrorSnackbar("Invalid Credentials");
		}

		// for reading we use refs
		// for showing we use states

		if (
			enteredEmail.trim().length === 0 ||
			enteredPassword.trim().length === 0
		) {
			return;
		}
	};

	return (
		<>
			<div
				className="relative flex flex-col justify-center min-h-screen overflow-hidden"
				// style={{
				//   backgroundImage: `url(${require("../assets/signup.jpg")})`,
				//   backgroundSize: "cover",
				//   backgroundPosition: "center",
				// }}
			>
				<div className="w-3xl p-10 m-auto bg-white rounded-md shadow-lg lg:max-w-3xl">
					<div class="flex flex-col">
						<h1 className="text-3xl font-bold text-center text-sky-700 underline">
							Welcome to 26 Miles Capital Dashboard
						</h1>
						<img
							class="mx-auto w-2/4"
							src={require("../assets/26milescapital_logo.jpg")}
						/>
					</div>

					<div className={classes.authcontent}>
						<Card>
							<form
								className={classes.form}
								onSubmit={submitHandler}
							>
								<div className={classes.control}>
									<label htmlFor="email">Email</label>
									<input
										type="text"
										required
										id="email"
										ref={emailInputRef}
									/>
								</div>
								<div className={classes.control}>
									<label htmlFor="password">Password</label>
									<input
										type="password"
										required
										id="password"
										ref={passwordInputRef}
									/>
								</div>
								<div className={classes.actions}>
									<button
										className={classes.button}
										type="submit"
									>
										Submit{" "}
									</button>
								</div>
                <div></div>
								<div>
									<GoogleOAuthProvider clientId="755704835366-gb7ksq4rg4tbae0ksvd4s7jb6bvkmhon.apps.googleusercontent.com">
										<GoogleLogin
											onSuccess={responseMessage}
											onError={errorMessage}
										/>
									</GoogleOAuthProvider>
								</div>
							</form>
						</Card>
					</div>
				</div>
			</div>
		</>
	);
};

export default Signup;
